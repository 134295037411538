.tooltip-container {
    position: relative;
    display: inline-block;
}
  
.tooltip-box {
    position: absolute;
    bottom: 100%; 
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px; 
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    width: 300px; 
    text-align: center; 
}
  
  