.red-outline {
  border: 1px solid red;
}

.file-input-div {
  /* display: flex; */
}

.upload-status {
  font-size: 12px;
}

.success-upload {
  color: green;
}

.unsuccess-upload {
  color: red;
}
.info-button {
  background-color: transparent;
  color: #333;
  border: 2px solid #333; /* adds a border */
  border-radius: 50%; /* makes the button circular */
  width: 20px; /* reduced size */
  height: 20px; /* reduced size */
  display: flex; /* added */
  justify-content: center; /* added */
  align-items: center; /* added */
  font-weight: normal; /* makes the 'i' less bold */
  cursor: pointer;
  padding: 0; /* added */
  font-size: 14px; /* added */
}
