.nav-bar {
  height: 50px;
  box-shadow: 0 3px 2px -2px rgb(165, 165, 165);
  margin-bottom: 10px;
}
.navbtn {
  background:none;
  border:none;
  margin:10px;
  padding:0;
  cursor: pointer;
}

#logo-img {
  max-height: 70%;
  padding-left: 20px;
}