.form-box-container {
    /* width: 100vw; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px 50px 0px;
}

.loading-circle {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #ccc;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.toggle-visibility-button {
    background-color: #f0f0f0;
    color: #333;
    border: 2px solid #333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
  }

  .visibilityToggleButton {

    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

.loginInputs {
    border-radius: 10px;
    width: 95%;
    flex-grow: 1;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.loginInputs2 {
    position: relative;
    flex-grow: 1;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
}
.formBox {
    border-radius: 25px;
    background: #efefef;
    padding: 20px;
    min-height: 300px;
    width: 70vw;
    border: 1px solid #e0e0e0;
}

button.displayButton{ 
    background-color:#E1BD7D; 
    text-align: center;
    color: #ffffff;
    padding: 14px 40px;
    border-radius: 20px;
    width: 40%;
} 

button.displayButton:hover{
    background-color: #cbaa71;
}

button.formButton{ 
    background-color:#151A37; 
    text-align: center;
    color: #e1e1e1;
    padding: 14px 40px;
    border-radius: 20px;
} 

button.formButton:hover{
    background-color: #202856;
}

.formEnterPage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px 50px 0px;
}

.formEnter {
    border-radius: 25px;
    color: #e1e1e1;
    background: #18344C;
    min-height: 300px;
    position: relative;
    width: 70vw;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.formEnterBody {
    position: relative;
    padding: 40px 0px 40px 40px;
}

.loginEnterBody {
    position: relative;
    text-align: center;
    padding: 40px 120px 40px 120px;
}

.formEnterText {
    width: 60%;
    position: absolute;
    z-index: 9;
}

.formEnterImg {
    position: relative;
    float: right;
}

.formEnterButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#splash-img {
    height: 375px;
}

.formSuccess {
    border-radius: 25px;
    color: #e1e1e1;
    background: #18344C;
    padding: 20px;
    min-height: 300px;

}

.form-errors {
    color: #d31334;
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.form-bullets {
    list-style: none;
}

.progress-bar {
    height: 70px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons-and-errors {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons {
    display: flex;
    justify-content: center;
}

button {
    margin: 5px;
}