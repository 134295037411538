.form-box-container {
    /* width: 100vw; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px 50px 0px;
}

.formBox {
    border-radius: 25px;
    background: #efefef;
    padding: 20px;
    min-height: 300px;
    width: 70vw;
    border: 1px solid #e0e0e0;
}

button.displayButton{ 
    background-color:#E1BD7D; 
    text-align: center;
    color: #ffffff;
    padding: 14px 40px;
    border-radius: 20px;
    width: 40%;
} 

button.displayButton:hover{
    background-color: #cbaa71;
}

button.formButton{ 
    background-color:#151A37; 
    text-align: center;
    color: #e1e1e1;
    padding: 14px 40px;
    border-radius: 20px;
} 

button.formButton:hover{
    background-color: #202856;
}

.formEnterPage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px 50px 0px;
}

.formEnter {
    border-radius: 25px;
    color: #e1e1e1;
    background: #18344C;
    min-height: 350px;
    position: relative;
    width: 70vw;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.formEnterBody {
    position: relative;
    padding: 40px 0px 40px 40px;
}

.formEnterText {
    width: 60%;
    position: relative;
    z-index: 9;
}

.formEnterImg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}

.formEnterButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#splash-img {
    height: 350px;
}

.formSuccess {
    border-radius: 25px;
    color: #e1e1e1;
    background: #18344C;
    padding: 20px;
    min-height: 300px;

}

.form-errors {
    color: #d31334;
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.form-bullets {
    list-style: none;
}

.loading-icon {
    display: flex;
    justify-content: center;
}

.progress-bar {
    height: 70px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons-and-errors {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons {
    display: flex;
    justify-content: center;
}

button {
    margin: 5px;
}

.success-upload {
    color: green;
}